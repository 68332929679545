import React from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';

const About = () => { 
  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])
  return (
    <div
      name="about"
      id="about"
      className="w-full h-screen bg-[#0a192f] text-gray-820"
    >
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="h-div py-16 rounded-md bg-cyan-800 flex flex-col justify-center items-center w-4/6">
          <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8 mb-4">
            <div className="sm:text-right pb-8 pl-4">
              <p className="text-4xl font-bold inline border-b-4 border-cyan-500">
                About
              </p>
            </div>
            <div></div>
          </div>
          <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
            <div className="sm:text-right text-4xl font-bold">
              <p>
                Hello, I'm Robertas Kirkickas, it's nice you landed here. Please take a
                look around.
              </p>
            </div>
            <div>
              <p>
                {" "}
                Fun fact: I started to be interested in programming since I was 11-12 years old, writing down 
                codes on paper and then re-writing them to my actual code online using my mobile phone which was with keypad. 
                My first programming language was WML - for mobile websites(wapsites) only.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;