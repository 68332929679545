import { Container, Row, Col } from "react-bootstrap";
import {
    FaGithub,
    FaLinkedin
  } from 'react-icons/fa';


const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div name='contact' className='w-full bg-[#081427] flex justify-center items-center p-4'>
        <div className='flex flex-col max-w-[600px] w-full text-gray-300'>
        <Container>
        <Row className="align-items-center">

          <Col size={12} sm={6} className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
          <p>RobertasKirkickas.dev <br /> Copyright {currentYear}. All Rights Reserved</p>
            <div className="flex flex-row mx-auto h-full">
              <a href='https://www.linkedin.com/in/robertas-kirkickas/' target="_blank" rel="noreferrer"><FaLinkedin size={30} alt="Icon" /></a>
              <a href='https://github.com/RobertasKirkickas' target="_blank" rel="noreferrer"><FaGithub size={30} alt="Icon" /></a>
            </div>
            
          </Col>
        </Row>
      </Container>
        </div>
    </div>
  )
}
export default Footer;