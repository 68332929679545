import React from 'react';
// import code from '../assets/code2.png';
import showhub from '../assets/SH.png';
import IMS from '../assets/IMS.png';
import SA from '../assets/SA.png';
const Works = () => {
  return (
    <div name='work' className='w-full md:h-screen text-gray-300 bg-[#0a192f]'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8 w-full flex justify-center items-center flex-col'>
          <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-cyan-500'>
            Projects
          </p>
          <p className='py-6 text-2xl'>Check out some of my most recent work</p>
        </div>
        {/* Container */}
        <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
          {/* Grid Item */}




          <div
            style={{ backgroundImage: `url(${IMS})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects 2 */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
              <span className=' text-lg font-bold text-white tracking-wider'>
                IMS
              </span>
              <p className='text-center'>Inventory Management System (Java)</p>
              <div className='pt-8 text-center'>
                {/* <a href='https://showhub.uosweb.co.uk/examples/search-algorithms.html' target="_blank" rel="noreferrer">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Live
                  </button>
              </a> */}
                <a href='https://github.com/RobertasKirkickas/inventory-management-system-java' target="_blank" rel="noreferrer">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>





          <div
            style={{ backgroundImage: `url(${SA})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects 2 */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
              <span className=' text-lg font-bold text-white tracking-wider'>
                Search Algorithms
              </span>
              <p className='text-center'>Search Algorithms (Python)</p>
              <div className='pt-8 text-center'>
                <a href='https://showhub.uosweb.co.uk/examples/search-algorithms.html' target="_blank" rel="noreferrer">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a href='https://github.com/RobertasKirkickas/search-algorithms-python' target="_blank" rel="noreferrer">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>






          <div
            style={{ backgroundImage: `url(${showhub})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects 2 */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
              <span className=' text-lg font-bold text-white tracking-wider'>
                ShowHub
              </span>
              <p className='text-center'>TV Shows Search Website.</p>
              <div className='pt-8 text-center'>
                <a href='https://showhub.uosweb.co.uk' target="_blank" rel="noreferrer">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a href='https://github.com/RobertasKirkickas/shows-website' target="_blank" rel="noreferrer">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>












          <div
            style={{ backgroundImage: `url(https://user-images.githubusercontent.com/121698117/213841310-f8f06ef7-a779-433e-84cd-95f8efb7007b.png` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
              <span className=' text-lg font-bold text-white tracking-wider'>
                Hangman game
              </span>
              <p className='text-center'>Hangman is a classic word guessing game where the player must guess a randomly generated word before running out of guesses.</p>
              <div className='pt-8 text-center'>
                <a href='https://robertas-kirkickas-hangman-game.netlify.app/' target="_blank" rel="noreferrer">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a href='https://github.com/RobertasKirkickas/Hangman-game' target="_blank" rel="noreferrer">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>





          <div
            style={{ backgroundImage: `url(https://user-images.githubusercontent.com/121698117/212209284-4dd34fe2-0135-44d9-87c7-d6a3e25f83b3.png)` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
              <span className=' text-lg font-bold text-white tracking-wider'>
                Website clone
              </span>
              <p className='text-center'>kevinmarkkeen.com website clone using React</p>
              <div className='pt-8 text-center'>
                <a href='https://robertas-kirkickas-kmk-website-clone.netlify.app/' target="_blank" rel="noreferrer">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a href='https://github.com/RobertasKirkickas/kevinmarkkeen-website-clone' target="_blank" rel="noreferrer">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>


          <div
            style={{ backgroundImage: `url(https://user-images.githubusercontent.com/121698117/212204246-480d8467-7030-4e02-89bb-9ec627751b91.png)` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
              <span className=' text-lg font-bold text-white tracking-wider'>
                StylishME
              </span>
              <p className='text-center'>Clothing website template created with React</p>
              <div className='pt-8 text-center'>
                <a href='https://robertas-kirkickas-stylish-me.netlify.app/' target="_blank" rel="noreferrer">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a href='https://github.com/RobertasKirkickas/Stylish-me' target="_blank" rel="noreferrer">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>





        </div>
      </div>
    </div>
  );
};
export default Works;